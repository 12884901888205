import React, { Component } from 'react'
import { Box ,Grid,TextField,Button} from '@mui/material'
import firebase from "../firebase"
export class Add extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        name:"",
        address : "",
        latitude : "",
        longitude : "",
        file:""
      }
      this.handleChange=this.handleChange.bind()
      this.handleChangee=this.handleChangee.bind()
    }
handleChange=(e)=>{
this.setState({[e.target.name]:e.target.value})
}

handleChangee=(e)=>{
  this.setState({[e.target.name]:e.target.files[0]})
}

Submit=()=>{
if(this.state.file.size<=100000){
  const collegekeyyy= firebase.database().ref("collegyyyhe").push().key

  var storageRef = firebase.storage().ref();
  var uploadTask = storageRef.child(collegekeyyy).put(this.state.file);


  // 3. Completion observer, called on successful completion
  uploadTask.on('state_changed', 
    (snapshot) => {
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          console.log('Upload is paused');
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          console.log('Upload is running');
          break;
      }
    }, 
    (error) => {
      // Handle unsuccessful uploads
    }, 
    () => {
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
       // console.log('File available at', downloadURL);

        if(this.state.address!==""&&this.state.file!=="" &&this.state.latitude!=="" &&this.state.longitude!=="")
       {
        const collegekeyyc= firebase.database().ref("collegyywlkelkyheprojec tkey").push().key
  firebase.database().ref("other_pg").child(collegekeyyc).set({
      name:this.state.name,
      id:collegekeyyc,
      address:this.state.address,
     latitude:this.state.latitude,
     longitude:this.state.longitude,
     img:downloadURL
  }).then(()=>{
    alert("successful")
  })
       }else{
        alert("All fields are complessary")
       }

      });
    }
  );
  
}else{
  alert("File size is too big allow below 1 MB file")
}
}


  render() {
    console.log(this.state.file,this.state.name,this.state.address,this.state.longitude,this.state.latitude)
    return (
      <div>
<Box sx={{mt:-4,m:2}}>
<Grid container spacing={2}>
  <Grid item xs={12} sm={4} md={4}>
  <TextField id="outlined-basic" fullWidth size='small' label=" Enter pg name" name="name" variant="outlined" onChange={this.handleChange} />
  </Grid>
  <Grid item xs={12} sm={4} md={4}>
  <TextField id="outlined-basic" fullWidth size='small' label="Enter pg address" name="address" variant="outlined" onChange={this.handleChange}/>
  </Grid>
  <Grid item xs={12} sm={4} md={4}>
  <TextField id="outlined-basic" fullWidth size='small' label="Enter Latitude" name="latitude" variant="outlined" onChange={this.handleChange}/>
  </Grid>
  <Grid item xs={12} sm={4} md={4}>
  <TextField id="outlined-basic" label="Enter Longitude" fullWidth size='small' variant="outlined" name="longitude" onChange={this.handleChange} />
  </Grid>
  <Grid item xs={12} sm={4} md={4}>
    <Box sx={{border:1}}>
       <input type='file' name='file' onChange={this.handleChangee} />
    </Box>
  </Grid>
</Grid>
<br/>

<Button variant="contained" onClick={this.Submit} size='small' disableElevation sx={{textTransform:'none'}}>Submit</Button>
</Box>




      </div>
    )
  }
}

export default Add